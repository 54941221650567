import request from '@/utils/request'

// 前往认证获取url
export function authenticationUrl() {
  return request({
    url: 'pc/qys/enterprise/auth/url',
    method: 'GET'
  })
}

// 前往授权获取url
export function accreditUrl(url) {
  return request({
    url: `/pc/qys/enterprise/auth/privilege/url?successUrl=${url}`,
    method: 'GET'
  })
}

// 获取当前是否认证和授权
export function jurisdiction() {
  return request({
    url: '/pc/qys/enterprise/auth/status',
    method: 'GET'
  })
}

// 获取文件列表
export function fileList(data) {
  return request({
    url: `/pc/qys/contract?pageIndex=${data?.pageIndex}&startDate=${data?.startDate}&endDate=${data?.endDate}&search=${data?.search}&status=${data?.status}&pageSize=${data?.pageSize}`,
    method: 'GET',
    // data: params
  })
}

// 获取企业信息
export function enterpriseMessage() {
  return request({
    url: '/pc/qys/enterprise/auth/result',
    method: 'GET'
  })
}

// 获取签署链接
export function signUrl(data) {
  return request({
    url: `/pc/qys/contract/sign/${data.id}`,
    method: 'POST',
    data: data.url
  })
}

// 获取文件详情
export function fileDetail(id) {
  return request({
    url: `/pc/qys/contract/${id}`,
    method: 'GET'
  })
}

// 下载单个
export function downloadItem(data) {
  return request({
    url: `/pc/qys/contract/download/${data.id}?fileType=${data.fileType}`,
    method: 'GET',
    responseType: 'blob'
  })
}

// 批量下载
export function batchDownload(data) {
  return request({
    url: '/pc/qys/contract/download/batch',
    method: 'POST',
    data,
    responseType: 'blob'
  })
}